<script>
// Import
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { Helper } from "../../../../helper.js";
import Config from "../../../../config.js";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import VueNumeric from "vue-numeric";
import { Circle8 } from "vue-loading-spinner";
import moment from "moment";
// import ComponentHasilSurvay from "./ComponentHasilSurvay.vue";
import ComponentFormAs from "./ComponentFormAs.vue";
import ComponentClientInformation from "./ComponentClientInformation.vue";
import ComponentApproval from "./ComponentApproval.vue";

import "vue-custom-range-slider/dist/vue-custom-range-slider.css";

export default {
  mixins: [Helper],
  components: {
    Layout,
    PageHeader,
    Multiselect,
    Circle8,
    DatePicker,
    VueNumeric,
    ComponentFormAs,
    ComponentApproval,
    ComponentClientInformation,
  },
  data() {
    return {
      dataFlagSurveyPerQues: [],
      dataFlagSurveyPerQuesId: [],
      showDataClientInformation: true,

      userStart: JSON.parse(localStorage.getItem("user")),
      data_url: Config,
      load:false,
      errors: [],
      datarerender:0,
      input_form: {
        id: "",
        partner_id: "",
        nama_proyek: "",
        tahun_kontrak: "",
        nilai_proyek: "",
        sektor_id: "",
        jenis_kontrak_id: "",
        paket_pekerjaan_id: "",
        pembayaran_id: "",
        status_contract_id: "",
        nama_mk: "",
        nama_qs: "",
        kota_id: "",
        state_id: "",
      },
      status_contract: "",
      tahun_kontrak: "",

      dataSurvay: "",
      dataNomor: 0,
      dataInputSurvay: {
        survey_id: "",
        answers: [],
      },
      indexSection: 0,
      indexSectionHigh: 0,
      totalSection: 0,

      c_sector: "",
      dataSelectSector: [],

      c_status_contract: "",
      dataSelectStatusContract: [],

      c_client_name: "",
      dataSelectClient: [],

      c_city: "",
      dataSelectCity: [],

      c_provinsi: "",
      dataSelectProvinsi: [],

      c_contract: "",
      dataSelectContract: [],

      c_work_package: "",
      dataSelectWorkPackage: [],

      c_how_pay: "",
      dataSelectHowPay: [],

      loading_send: false,

      tab: 1,
      title: this.$root.$t("pageKarya.clientAssesment.title"),

      items: [
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_1"),
        },
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_2"),
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,

      dataEditQuestion: [],
      isEdit: false,
      idWorkflowApprove: "",
    };
  },
  computed: {
    v_data_sector_computed: {
      get() {
        return this.c_sector;
      },
      set(val) {
        this.c_sector = val;
        this.input_form.sektor_id = val.id;
      },
    },

    v_data_status_contract_computed: {
      get() {
        return this.c_status_contract;
      },
      set(val) {
        this.c_status_contract = val;
        this.input_form.status_contract_id = val.id;
      },
    },

    v_data_contract_computed: {
      get() {
        return this.c_contract;
      },
      set(val) {
        this.c_contract = val;
        this.input_form.jenis_kontrak_id = val.id;
      },
    },

    v_data_client_name_computed: {
      get() {
        return this.c_client_name;
      },
      set(val) {
        this.c_client_name = val;
        this.input_form.partner_id = val != null ? val.id : "";
      },
    },

    v_data_city_computed: {
      get() {
        return this.c_city;
      },
      set(val) {
        this.c_city = val;
        this.input_form.kota_id = val.id;
      },
    },

    v_data_provinsi_computed: {
      get() {
        return this.c_provinsi;
      },
      set(val) {
        this.c_provinsi = val;
        this.input_form.state_id = val.id;
        this.c_city = null;
        this.input_form.kota_id = "";
        this.dataSelectCity = [];
        this.ApiCallDataCity();
      },
    },

    v_data_work_package_computed: {
      get() {
        return this.c_work_package;
      },
      set(val) {
        this.c_work_package = val;
        this.input_form.paket_pekerjaan_id = val.id;
      },
    },

    v_data_how_pay_computed: {
      get() {
        return this.c_how_pay;
      },
      set(val) {
        this.c_how_pay = val;
        this.input_form.pembayaran_id = val.id;
      },
    },
  },
  mounted() {
    this.ApiCallDataClient();
    this.ApiCallDataSector();
    this.ApiCallDataSurvay();
    this.ApiCallDataContract();
    this.ApiCallDataWorkPackage();
    this.ApiCallDataHowPay();
    this.ApiCallDataProvinsi();
    this.ApiCallDataStatusContract();
  },

  methods: {
    batalEdit() {
      this.tab = 3;
      this.isEdit = false;
      this.indexSection = 0;
    },

    edit(data) {
      if (Config.modeDev) console.log(data);
      this.tab = 2;
      this.isEdit = true;
      this.idWorkflowApprove = data.id;

      let no = 0;
      this.dataInputSurvay = {
        survey_id: data.survey_id,
        answers: [],
      };

      for (let i = 0; i < data.sections.length; i++) {
        for (let q = 0; q < data.sections[i].questions.length; q++) {
          this.dataEditQuestion[no] = data.sections[i].questions[q];
          no++;
        }
      }

      if (Config.modeDev) console.log(this.dataEditQuestion);
      //client name
      this.c_client_name = {
        name: data.contact_line.parner_name,
        id: data.contact_line.partner_id,
      };
      //client id
      this.input_form.id = data.contact_line.id;
      //partner id
      this.input_form.partner_id = data.contact_line.partner_id;
      //project name
      this.input_form.nama_proyek = data.contact_line.nama_proyek;
      //Sector Name
      this.c_sector = {
        name: data.contact_line.sektor_name,
        id: data.contact_line.sektor_id,
      };
      this.input_form.sektor_id = data.contact_line.sektor_id;
      //project name
      this.input_form.nama_proyek = data.contact_line.nama_proyek;
      //contract year
      this.tahun_kontrak = new Date(data.contact_line.tahun_kontrak + "-04-03");
      //contract status *
      this.c_status_contract = {
        id: data.contact_line.status_contract_id,
        name: data.contact_line.status_contract_name,
      };
      this.input_form.status_contract_id = data.contact_line.status_contract_id;
      //State *
      this.c_provinsi = {
        id: data.contact_line.state_id,
        name: data.contact_line.state_name,
      };
      this.input_form.state_id = data.contact_line.state_id;
      this.ApiCallDataCity();
      //city
      this.c_city = {
        id: data.contact_line.kota_id,
        name: data.contact_line.kota_name,
      };
      this.input_form.kota_id = data.contact_line.kota_id;
      // nilai project
      this.input_form.nilai_proyek = data.contact_line.nilai_proyek;
      // Contract Type
      this.c_contract = {
        id: data.contact_line.jenis_kontrak_id,
        name: data.contact_line.jenis_kontrak_name,
      };
      this.input_form.jenis_kontrak_id = data.contact_line.jenis_kontrak_id;
      // projecct package
      this.c_work_package = {
        id: data.contact_line.paket_pekerjaan_id,
        name: data.contact_line.paket_pekerjaan_name,
      };
      this.input_form.paket_pekerjaan_id = data.contact_line.paket_pekerjaan_id;
      // Payment Type
      this.c_how_pay = {
        id: data.contact_line.jenis_pembayaran_id,
        name: data.contact_line.jenis_pembayaran_name,
      };
      this.input_form.pembayaran_id = data.contact_line.jenis_pembayaran_id;
      // Manager Contruction
      this.input_form.nama_mk = data.contact_line.nama_mk;
      // Quantity Surveyor
      this.input_form.nama_qs = data.contact_line.nama_qs;
    },

    async backSurvey() {
      // Pindah Survey
      this.indexSection = this.indexSection - 1;
      document
        .getElementById("viewSurvey")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    },

    async nextSurvey() {
      // pengecekan apabila flag survey paling terkhir dengan survey dibuka sama, eksekusi flag kelolosan,
      // kalo tidak maka tidak usah dijlanakan kelolosan untuk servuew sebelumnya
      if (this.indexSectionHigh == this.indexSection) {
        let flagLolos = true;

        //pengecekan survay lolos setiap ques di section saat ini
        for (let i = 0; i < this.dataFlagSurveyPerQues.length; i++) {
          if (this.dataFlagSurveyPerQues[i] == false) {
            flagLolos = false;
            document.getElementById("queshv"+this.dataFlagSurveyPerQuesId[i]).scrollIntoView({block:"center"});
            document.getElementById("queshv"+this.dataFlagSurveyPerQuesId[i]).classList.add("alerts-border");
            break;
          }
        }

        if (flagLolos == false) {
          
          this.$notify({
            type: "error",
            group: "foo",
            title: "Please, Correct ",
            text: "Required Field ",
          });
        } else {
          // ketika pindah section ques
          this.indexSection = this.indexSection + 1;
          this.indexSectionHigh = this.indexSection;
          document
            .getElementById("viewSurvey")
            .scrollIntoView({ behavior: "smooth", block: "start" });

          // ketika pindah ke section berikutnya
          //define flag kelolosan untuk nilai false, setiap ques pada next section.
          for (
            let i = 0;
            i < this.dataSurvay.sections[this.indexSection].questions.length;
            i++
          ) {
            this.dataFlagSurveyPerQues[i] = false;
          }
        }
      } else {
        // tidak usah dicek kelolosan di survey sebelumnya
        this.indexSection = this.indexSection + 1;
        document
          .getElementById("viewSurvey")
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },

    callbackDataFormComponent(
      data,
      indexAnswer,
      no_data,
      flagLolos,
      indexPosisiArray
    ) {
      if (Config.modeDev) console.log(flagLolos);
      this.dataFlagSurveyPerQues[indexPosisiArray] = flagLolos;
      this.dataFlagSurveyPerQuesId[indexPosisiArray] = data.question_id;
      
      if(flagLolos) document.getElementById("queshv"+data.question_id).classList.remove("alerts-border");
      if (no_data == false) {
      
        this.dataInputSurvay.answers[indexAnswer] = {
          question_id: data.question_id,
          answer: data.answer,
          any_data: "yes",
        };
      } else {
        this.dataInputSurvay.answers[indexAnswer] = {
          question_id: data.question_id,
          answer: data.answer,
          any_data: "no",
        };
      }

      if (Config.modeDev) console.log(this.dataInputSurvay);
    },

    cleanSurvay(){

      console.log(JSON.stringify(this.dataInputSurvay.answers));

      for(var u=0;u<this.dataInputSurvay.answers.length;u++){
          for(var i=0;i<this.dataSurvay.sections[this.indexSection].questions.length;i++){
          console.log(this.dataInputSurvay.answers[u].question_id + '+' +  this.dataSurvay.sections[this.indexSection].questions[i].id );
          if( this.dataInputSurvay.answers[u].question_id == this.dataSurvay.sections[this.indexSection].questions[i].id ){
              delete this.dataInputSurvay.answers[u];
              break;
          }
        }
      }

      this.datarerender += 1;

      // this.dataInputSurvay = {
      //   survey_id: "",
      //   answers: [],
      // };

      // this.indexSection = 0;

      // this.ApiCallDataSurvay();
    },

    cancelEdit() {
      this.input_form = {
        id: "",
        partner_id: "",
        nama_proyek: "",
        kota_id: "",
        status_contract: "",
        tahun_kontrak: "",
        nilai_proyek: "",
        sektor_id: "",
        jenis_kontrak_id: "",
        paket_pekerjaan_id: "",
        pembayaran_id: "",
        nama_mk: "",
        nama_qs: "",
      };

      this.dataInputSurvay = {
        survey_id: "",
        answers: [],
      };
      this.c_sector = "";
      this.c_status_contract = "";
      this.c_contract = "";
      this.c_client_name = "";
      this.c_city = "";
      this.c_provinsi = "";
      this.c_work_package = "";
      this.c_how_pay = "";

      this.indexSection = 0;

      this.ApiCallDataSurvay();
    },

    deleteRecord(data) {
      this.$bvModal
        .msgBoxConfirm("Are you sure wants to delete?", {
          title: "Please Confirm",
          size: "mm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.listItems.splice(data.index, 1);
          }
        });
    },

    //-------------- API CALL DATA SELECT ------------------------------

    async ApiCallDataClient() {
      let formData = new URLSearchParams();
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getContact,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectClient = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    defaultValueField(type) {
      if (type == "numerical_box") {
        return "0";
      } else {
        return "";
      }
    },

    async ApiCallDataSurvay() {
      let formData = new URLSearchParams();
      // formData.append('perpage', 9999);
      // formData.append('page', 1);
      formData.append("state", "open");
      this.load = true;

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getSurvey,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", // "text/html" //"multipart/form-data"
            },
          }
        )
        .then((res) => {
          this.dataSurvay = "";
          this.dataInputSurvay = {
            survey_id: "",
            answers: [],
          };
          this.totalSection = 0;
          this.indexSection = 0;
          this.indexSectionHigh = 0;
          this.dataFlagSurveyPerQues = [];

          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSurvay = res.data.resultdata[0];

            this.dataInputSurvay.survey_id = this.dataSurvay.id;
            this.totalSection = this.dataSurvay.sections.length;
            let no = 0;

            for (let q = 0; q < this.dataSurvay.sections.length; q++) {
              for (
                let q1 = 0;
                q1 < this.dataSurvay.sections[q].questions.length;
                q1++
              ) {
                this.dataSurvay.sections[q].questions[q1].indexAnswer = no;
                no++;
              }
            }

            for (
              let i = 0;
              i < this.dataSurvay.sections[0].questions.length;
              i++
            ) {
              this.dataFlagSurveyPerQues[i] = false;
            }

            if (Config.modeDev) console.log(this.dataSurvay);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

        this.load = false;
    },

    async ApiCallDataSector() {
      await axios
        .post(process.env.VUE_APP_AUTHDOMAIN + this.data_url.getSektor, null, {
          headers: {
            "Forca-Token": this.userStart.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
          },
        })
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectSector = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataStatusContract() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getStatusContract,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectStatusContract = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataProvinsi() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getCountryState,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectProvinsi = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataCity() {
      let formData = null;
      if (this.input_form.state_id != "") {
        formData = new URLSearchParams();
        formData.append("state_id", String(this.input_form.state_id));
      }

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getLokasi,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded", // "text/html" //"multipart/form-data"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectCity = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataWorkPackage() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getPaketPekerjaan,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectWorkPackage = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataContract() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getJenisKontrak,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectContract = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiCallDataHowPay() {
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getCaraPembayaran,
          null,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data", // "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.dataSelectHowPay = res.data.resultdata;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    async ApiInputDataAll() {

      console.log(this.tahun_kontrak);

      
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success ml-2",
        },
        buttonsStyling: false,
      });

      try {
        this.errors = [];
        // let th = this;
        let boolEditApprove = false;
        if (Config.modeDev) console.log(this.tahun_kontrak);
        if (Config.modeDev) console.log(this.input_form);
        if (Config.modeDev) console.log(this.dataInputSurvay);
        if (Config.modeDev) console.log(JSON.stringify(this.dataInputSurvay));
        this.input_form.nilai_proyek = this.input_form.nilai_proyek.toString();

        // clone object, javascript tak bisa copy object.
        let inputForm2 = JSON.parse(JSON.stringify(this.input_form));
        if (Config.modeDev) console.log(inputForm2);

        if (this.tahun_kontrak != "" && this.tahun_kontrak != null) {
          inputForm2.tahun_kontrak = moment(this.tahun_kontrak).format("YYYY");
        } else {
          this.errors.push("Please, Contract Year Required");
        }

        if (inputForm2.status_contract_id == "") {
          delete inputForm2.status_contract_id;
        }

        if (inputForm2.kota_id == "") {
          delete inputForm2.kota_id;
        }

        if (inputForm2.id == "") {
          delete inputForm2.id;
        } else {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "btn btn-danger",
              cancelButton: "btn btn-success ml-2",
            },
            buttonsStyling: false,
          });

          await swalWithBootstrapButtons
            .fire({
              title: "Submit and Approve \n this Document ?",
              text: "You won't be able to revert this !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Cancel",
              cancelButtonText: "Yes, Approve !",
            })
            .then((result) => {
              if (!result.isConfirmed) {
                boolEditApprove = true;
              }
            });

          if (boolEditApprove == false) throw "Edit Exit";
        }

        //pengecekan survay lolos setiap ques di section saat ini
        for (let i = 0; i < this.dataFlagSurveyPerQues.length; i++) {
          if (this.dataFlagSurveyPerQues[i] == false) {
            document.getElementById("queshv"+this.dataFlagSurveyPerQuesId[i]).scrollIntoView();
            this.errors.push("Please, Input Required in Survey");
            break;
          }
        }

        let answer = this.dataInputSurvay.answers.filter(function (el) {
          if (el != null) {
            if (el.any_data == "yes") {
              el = {
                answer: el.answer,
                question_id: el.question_id,
              };
              return el;
            }
          }
        });

        if (!inputForm2.partner_id) {
          this.errors.push("Client Name");
        }

        if (!inputForm2.status_contract_id) {
          this.errors.push("Contract Status");
        }

        if (!inputForm2.jenis_kontrak_id) {
          this.errors.push("Contract Type");
        }

        if (!inputForm2.nama_proyek) {
          this.errors.push("Project Name");
        }

        if (!inputForm2.status_contract_id) {
          this.errors.push("Contract Status");
        }

        if (!inputForm2.sektor_id) {
          this.errors.push("Sector Name");
        }

        if (!inputForm2.paket_pekerjaan_id) {
          this.errors.push("Work Package");
        }

        if (!inputForm2.pembayaran_id) {
          this.errors.push("Payment");
        }

        if (!inputForm2.state_id) {
          this.errors.push("State");
        }

        if (!inputForm2.nilai_proyek) {
          this.errors.push("Value Project");
        }

        for (let i = 0; i < this.errors.length; i++) {
          this.$notify({
            type: "error",
            group: "foo",
            title: "Please, Correct ",
            text: "Required Field : " + this.errors[i],
          });
        }

        if (this.errors.length == 0) {
          let data = {
            contact_line: inputForm2,
            survey: {
              survey_id: this.dataInputSurvay.survey_id,
              answers: answer,
            },
          };

          if (Config.modeDev) console.log(inputForm2);
          if (Config.modeDev) console.log(JSON.stringify(inputForm2));
          this.loading_send = true;
          this.showDataClientInformation = false;
          this.$confetti.start();
          await axios
            .post(
              process.env.VUE_APP_AUTHDOMAIN +
                this.data_url.submitSurveyPageJSONType,
              data,
              {
                headers: {
                  "Forca-Token": this.userStart.token,
                  Accept: "application/json",
                  "Content-Type": "application/json", // "multipart/form-data" // "application/x-www-form-urlencoded" // "text/html"
                },
              }
            )
            .then(async (res) => {
              if (Config.modeDev) console.log(res);
              if (res.data.codestatus == "S") {
                if (boolEditApprove) {
                  await this.approve(this.idWorkflowApprove);
                } else {
                  await swalWithBootstrapButtons.fire(
                    "Success !",
                    "Assessment be saved",
                    "success"
                  );
                }

                if (this.isEdit) {
                  this.batalEdit();
                } else {
                  this.tab = 1;
                  this.cancelEdit();
                }
              } else {
                this.wrongTokenCo(res);
              }
            })
            .catch((error) => {
              if (this.is_runTest == false) {
                console.log(error);
              }

              try {
                if (error.response.status === 401) {
                  this.callLogOut();
                }
              } catch (error) {
                if (this.is_runTest == false) {
                  console.log(error);
                }
              }
            });

          this.showDataClientInformation = true;
          this.loading_send = false;
          this.$confetti.stop();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async approve(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      let formData = new URLSearchParams();
      formData.append("id", id);
      formData.append("code", "survey");

      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.actionApproveWorkflow,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then(async (res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            await swalWithBootstrapButtons.fire(
              "Approved!",
              "Your file has been approved.",
              "success"
            );
          } else {
            swalWithBootstrapButtons.fire(
              "Wrong System !",
              res.data.message,
              "info"
            );

            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });
    },

    refreshCLientInformation() {
      this.$refs.comClientInformation.ApiCallDataClientAssesment();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.tbdetail tr td:first-child {
  font-weight: bold;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">

              <b-tab :active="tab == 1" @click="tab = 1" title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{
                    $t("pageKarya.clientAssesment.tab_title_view")
                  }}</a>
                </template>
                <ComponentClientInformation
                  v-if="showDataClientInformation"
                  ref="comClientInformation"
                />
              </b-tab>

              <b-tab
                :active="tab == 3"
                @click="tab = 3"
                v-if="userStart.role.code == '2'"
                title-link-class="p-3"
              >
                <template v-slot:title>
                  <a class="font-weight-bold active">{{
                    $t("pageKarya.clientAssesment.tab_title_approval")
                  }}</a>
                </template>
                <ComponentApproval
                  v-if="showDataClientInformation"
                  :refreshCLientInformation="refreshCLientInformation"
                  :edit="edit"
                />
              </b-tab>

              <b-tab
                :active="tab == 2"
                @click="tab = 2"
                v-if="userStart.role.code == '1' || isEdit"
                title-link-class="p-3"
              >
                <template v-slot:title>
                  <a class="font-weight-bold active">{{
                    isEdit
                      ? $t("pageKarya.clientAssesment.tab_title_add_2")
                      : $t("pageKarya.clientAssesment.tab_title_add")
                  }}</a>
                </template>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="">
                      <div class="">
                        <button
                          v-if="isEdit"
                          @click="batalEdit"
                          class="btn btn-warning mb-3"
                        >
                          Cancel Edit
                        </button>

                        <div class="row">
                          <div class="col-6">
                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Client Name *"
                              label-for="email"
                            >
                              <multiselect
                                :options="dataSelectClient"
                                v-model="v_data_client_name_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example text"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Project Name * "
                            >
                              <b-form-input
                                for="text"
                                value=""
                                v-model="input_form.nama_proyek"
                              ></b-form-input>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Sector Name *"
                            >
                              <multiselect
                                :options="dataSelectSector"
                                v-model="v_data_sector_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Contract Year *"
                            >
                              <date-picker
                                v-model="tahun_kontrak"
                                type="year"
                                lang="en"
                                placeholder="Select Year"
                              ></date-picker>
                            </b-form-group>

                            <b-form-group
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Contract Status *"
                            >
                              <multiselect
                                :options="dataSelectStatusContract"
                                v-model="v_data_status_contract_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="State *"
                            >
                              <multiselect
                                :options="dataSelectProvinsi"
                                v-model="v_data_provinsi_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="City"
                            >
                              <multiselect
                                :options="dataSelectCity"
                                v-model="v_data_city_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>
                          </div>

                          <div class="col-6">
                            <input
                              type="hidden"
                              name="id"
                              v-model="input_form.id"
                            />

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Value Project *"
                            >
                              <vue-numeric
                                class="form-control"
                                currency="Rp "
                                separator="."
                                v-model="input_form.nilai_proyek"
                              ></vue-numeric>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Contract Type *"
                            >
                              <multiselect
                                :options="dataSelectContract"
                                v-model="v_data_contract_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Project Package *"
                            >
                              <multiselect
                                :options="dataSelectWorkPackage"
                                v-model="v_data_work_package_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Payment type *"
                            >
                              <multiselect
                                :options="dataSelectHowPay"
                                v-model="v_data_how_pay_computed"
                                label="name"
                              ></multiselect>
                            </b-form-group>

                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Manager Construction"
                            >
                              <b-form-input
                                value=""
                                v-model="input_form.nama_mk"
                              ></b-form-input>
                            </b-form-group>
                            
                            <b-form-group
                              id="example-email"
                              label-cols-sm="2"
                              label-cols-lg="4"
                              label="Quantity Surveyor"
                            >
                              <b-form-input
                                value=""
                                v-model="input_form.nama_qs"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row" id="viewSurvey" v-if="!load">
                          <div class="col-lg-12 mt-5">
                            <div
                              :key="datarerender"
                              class="card shadow-none"
                              v-if="dataSurvay != ''"
                              style="
                                background-size: 100px 40px;
                                background-repeat: repeat;
                                background-image: url('@/assets/images/lg.png');
                              "
                            >
                              <div class="card-body text-center">
                                <h4
                                  class="card-title font-poppins"
                                  style="font-size: 26px; font-weight: bold"
                                >
                                  Survey - {{ dataSurvay.title }}
                                </h4>
                                <p
                                  class="font-poppins"
                                  style="font-size: 20px; font-weight: 600"
                                >
                                  {{ dataSurvay.sections.length }} Sections
                                </p>
                                <p
                                  class="card-title-desc"
                                  style="font-size: 20px"
                                >
                                  Please, Fill in the survey
                                </p>
                              </div>

                              <div class="row mx-4 d-flex justify-content-end">
                                <template v-for="(v, i) in dataSurvay.sections">
                                  <div
                                    v-if="i != indexSection"
                                    :title="v.name"
                                    class="circlee22 mb-2 float-right"
                                    style="
                                      width: 32px;
                                      height: 32px;
                                      padding-top: 5px;
                                    "
                                    v-bind:key="i"
                                  >
                                    <span
                                      class="font-poppins"
                                      style="font-weight: bold"
                                      >{{ i + 1 }}</span
                                    >
                                  </div>

                                  <div
                                    v-if="i == indexSection"
                                    :title="v.name"
                                    class="circlee4 mb-2 float-right"
                                    style="
                                      width: 32px;
                                      height: 32px;
                                      padding-top: 5px;
                                      color: #ffffff;
                                    "
                                    v-bind:key="i"
                                  >
                                    <span
                                      class="font-poppins"
                                      style="font-weight: bold"
                                      >{{ i + 1 }}</span
                                    >
                                  </div>

                                  <div class="px-0" v-bind:key="i+'slip'" v-if="i < dataSurvay.sections.length - 1" style="margin-top:15px; width:5px; height:4px; background-color:#d9d9d9;"></div>

                                </template>
                              </div>
                              <div
                                class="form-group mx-4"
                                v-for="(item, index) in dataSurvay.sections"
                                :key="index"
                              >
                                <div v-if="index == indexSection">
                                  <div
                                    class="alert alert-info shadow-lg font-weight-bold"
                                  >
                                    {{ item.title }}
                                  </div>
                                  <template v-for="(item2, index2) in item.questions">
                                  <div
                                    :key="index2"
                                    :id="'queshv'+item2.id"
                                  >
                                    <component-form-as
                                      :isEdit="isEdit"
                                      :dataEdit="dataEditQuestion"
                                      :index="index2"
                                      :callbackDataFormComponent="
                                        callbackDataFormComponent
                                      "
                                      :dataInputSurvay="dataInputSurvay"
                                      :item="item2"
                                      v-if="item2.question_type == 'karya'"
                                    >
                                    </component-form-as>
                                  </div>
                                  </template>
                                  <div
                                    :class="'row d-flex justify-content-between'"
                                  >
                                    <b-button
                                      @click="backSurvey()"
                                      v-show="indexSection != 0"
                                      variant="secondary"
                                      ><i class="fas fa-angle-left mt-1"></i>
                                      Back Survey
                                    </b-button>

                                    <b-button
                                      v-if="!isEdit"
                                      @click="cleanSurvay()"
                                      variant="warning"
                                    >
                                      Clean Survey
                                    </b-button>

                                    <b-button
                                      @click="nextSurvey()"
                                      v-if="indexSection < totalSection - 1"
                                      variant="primary"
                                      >Next Survey
                                      <i class="fas fa-angle-right mt-1"></i
                                    ></b-button>
                                    <b-button
                                      @click="ApiInputDataAll()"
                                      v-if="
                                        indexSection == totalSection - 1 &&
                                        !loading_send
                                      "
                                      variant="primary"
                                      >{{
                                        $t(
                                          "pageKarya.clientAssesment.button.button1"
                                        )
                                      }}</b-button
                                    >
                                    <div
                                      v-if="loading_send"
                                      class="float-right"
                                    >
                                      <Circle8></Circle8>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
