<template>
  <div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="table-responsive">
      <b-table
        class="table-centered"
        :items="ordersData"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(kodefikasi)="data">
          <a
            class="text-black"
            v-b-tooltip.hover
            :id="'tt' + data.item.contact_line.id"
          >
            {{ data.item.contact_line.kodefikasi }}
          </a>

          <b-tooltip
            :target="'tt' + data.item.contact_line.id"
            placement="rightbottom"
          >
            <div
              style="
                width: 200px;
                min-height: 60px;
                position: relative;
                margin-top: -10px;
                margin-left: -10px;
              "
            >
              <span
                style="
                  font-weight: bold;
                  text-align: center;
                  font-size: 40px;
                  color: rgba(255, 255, 255, 0.2);
                "
              >
                {{ userStart.ad_user_name }}</span
              >
              <div
                class="text-white"
                style="
                  padding-top: 3px;
                  font-size: 14px;
                  text-align: center;
                  margin: auto;
                  position: absolute;
                  top: 25%;
                  bottom: 25%;
                  left: 5%;
                  right: 5%;
                  min-height: 60px;
                "
              >
                {{ data.item.contact_line.parner_name }}
              </div>
            </div>
          </b-tooltip>
        </template>
        <template v-slot:cell(detail)="data">
          <div style="text-align: left">
            <a
              @click="data.toggleDetails"
              class="text-primary"
              v-b-tooltip.hover
              title="View Detail"
              style="cursor: pointer"
            >
              <i class="far fa-eye font-size-18" style="margin-top: -5px"></i>
            </a>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <table class="tbdetail">
                    <tr>
                      <td>MK Name</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.nama_mk }}</td>
                    </tr>
                    <tr>
                      <td>QS Name</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.nama_qs }}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.state_name }}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.kota_name }}</td>
                    </tr>
                    <tr>
                      <td>Sector Name</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.sektor_name }}</td>
                    </tr>
                    <tr>
                      <td>Project Value</td>
                      <td>:</td>
                      <td>
                        Rp
                        {{
                          numberWithCommas(row.item.contact_line.nilai_proyek)
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>Status Contract</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.status_contract_name }}</td>
                    </tr>
                    <tr>
                      <td>Contract Year</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.tahun_kontrak }}</td>
                    </tr>
                    <tr>
                      <td>Segmentation</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.segmentasi_name }}</td>
                    </tr>
                    <tr>
                      <td>Contract Type</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.jenis_kontrak_name }}</td>
                    </tr>
                    <tr>
                      <td>Work Package</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.paket_pekerjaan_name }}</td>
                    </tr>
                    <tr>
                      <td>Payment</td>
                      <td>:</td>
                      <td>{{ row.item.contact_line.jenis_pembayaran_name }}</td>
                    </tr>
                  </table>
                </div>
                <div class="mt-3 d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    @click="gotoedit(row.item)"
                    >Edit Info &amp; Assessment</b-button
                  >
                  <b-button
                    variant="success ml-2"
                    @click="approve(row.item.id)"
                    class="mr-2"
                  >
                    Approve</b-button
                  >
                </div>
              </div>
              <div class="col-lg-8">
                <ComponentHasilSurvay :data-client="row" :from="'approve'" />
              </div>
            </div>
          </b-card>
        </template>
      </b-table>
      <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
    </div>
    <div class="row mb-5">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import axios from "axios";
import { Helper } from "../../../../helper.js";
import Config from "../../../../config.js";
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import VueNumeric from "vue-numeric";
// import { Circle8 } from "vue-loading-spinner";
// import moment from "moment";
import ComponentHasilSurvay from "./ComponentHasilSurvay.vue";
// import ComponentFormAs from "./ComponentFormAs.vue";
// import ComponentApproval from "./ComponentApproval.vue";

import "vue-custom-range-slider/dist/vue-custom-range-slider.css";
import Swal from "sweetalert2";

export default {
  mixins: [Helper],
  props: {
    edit: Function,
    refreshCLientInformation:Function
  },
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // Circle8,
    // DatePicker,
    // VueNumeric,
    ComponentHasilSurvay,
    // ComponentFormAs,
    // ComponentApproval,
  },
  data() {
    return {
      data_url: Config,
      dataFlagSurveyPerQues: [],

      userStart: JSON.parse(localStorage.getItem("user")),
      errors: [],
      status_contract: "",
      tahun_kontrak: "",

      dataSurvay: "",
      dataNomor: 0,
      dataInputSurvay: {
        survey_id: "",
        answers: [],
      },
      indexSection: 0,
      indexSectionHigh: 0,
      totalSection: 0,

      loading_send: false,

      tab: 1,
      title: this.$root.$t("pageKarya.clientAssesment.title"),
      loading: false,
      items: [
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_1"),
        },
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_2"),
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        {
          key: "kodefikasi",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th0"),
        },
        {
          key: "contact_line.sektor_name",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th1"),
        },
        {
          key: "contact_line.nama_proyek",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th2"),
        },
        {
          key: "contact_line.kota_name",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th3"),
        },
        {
          key: "contact_line.paket_pekerjaan_name",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th4"),
        },
        {
          key: "contact_line.tahun_kontrak",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th5"),
        },
        { key: "detail" },
      ],
    };
  },
  computed: {
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    this.ApiCallDataClientAssesment();
  },

  methods: {
    gotoedit(row) {
      this.edit(row);
    },
    async ApiCallDataClientAssesment() {
      this.loading = true;
      let formData = new URLSearchParams();
      formData.append("code", "survey");
      if (Config.modeDev) console.log(this.userStart);
      if (Config.modeDev)
        console.log(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getListActivity
        );
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getListActivity,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.ordersData = res.data.resultdata;
            this.totalRows = this.ordersData.length;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    approve(id) {
      let th = this;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Submit and Approve \n this Document ?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Cancel",
          cancelButtonText: "Yes, Approve !",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (!result.value) {
            let formData = new URLSearchParams();
            formData.append("id", id);
            formData.append("code", "survey");

            this.$confetti.start();
            await axios
              .post(
                process.env.VUE_APP_AUTHDOMAIN +
                  this.data_url.actionApproveWorkflow,
                formData,
                {
                  headers: {
                    "Forca-Token": this.userStart.token,
                    // "Accept": "application/json",
                    // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
                  },
                }
              )
              .then( async (res) => {
                if (Config.modeDev) console.log(res);
                if (res.data.codestatus == "S") {
                  th.ordersData = [];
                  th.ApiCallDataClientAssesment();
                  th.refreshCLientInformation();

                  await swalWithBootstrapButtons.fire(
                    "Approved!",
                    "Your file has been approved.",
                    "success"
                  );
                } else {
                  swalWithBootstrapButtons.fire(
                    "Wrong System !",
                    res.data.message,
                    "info"
                  );

                  this.wrongTokenCo(res);
                }
              })
              .catch((error) => {
                if (this.is_runTest == false) {
                  console.log(error);
                }

                try {
                  if (error.response.status === 401) {
                    this.callLogOut();
                  }
                } catch (error) {
                  if (this.is_runTest == false) {
                    console.log(error);
                  }
                }
              });

            this.$confetti.stop();
          }
        });
    },
  },
};
</script>
