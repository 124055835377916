<style>
.bg-abu {
  background-color: #eaeff3;
}
</style>

<template>
  <div class="row">
    <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
    {{ dataSurvay }}
    <table class="table table-bordered">
      <tr class="text-center" style="font-size: 18px">
        <th><b>No</b></th>
        <th><b>Survey</b></th>
      </tr>
      <template v-for="(v, i) in data.sections">
        <tr :key="'1tr-' + i" v-if="v.questions.length > 0">
          <td class="" colspan="2" style="font-size: 16px; font-weight: bold">
            <i class="ri-folder-shared-line"></i> {{ v.title }}
          </td>
        </tr>
        <tr v-for="(v2, index2) in v.questions" v-bind:key="i+'-'+index2">
          <td>{{ index2 + 1 }}</td>
          <td>
            <span style="font-size: 16px; font-weight: bold">
              {{ v2.question_title }}
            </span>
            <br /><br />
            <table class="table">
              <tr>
                <th class="bg-abu" width="200">Contractual</th>
                <td>{{ v2.contractual.contractual_value }}</td>
              </tr>
              <tr>
                <th class="bg-abu">Contractual Desc</th>
                <td>{{ v2.contractual_desc }}</td>
              </tr>
              <tr>
                <th class="bg-abu">Nilai</th>
                <td>{{ v2.nilai }}</td>
              </tr>
              <tr>
                <th class="bg-abu">Realisasi</th>
                <td>{{ v2.realisasi.realisasi_value }}</td>
              </tr>
              <tr>
                <th class="bg-abu">Realisasi Desc</th>
                <td>{{ v2.realisasi_desc }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import { Helper } from "../../../../helper.js";
import Config from "../../../../config.js";
import axios from "axios";

export default {
  mixins: [Helper],
  props: {
    from: String,
    dataClient: Object,
  },
  data() {
    return {
      dataSurvay: "Tidak Ada Data Survey",
      data_url: Config,
      userStart: JSON.parse(localStorage.getItem("user")),
      loading: false,
      data: "",
    };
  },
  mounted() {
    if (Config.modeDev)console.log(this.dataClient);
    this.ApiCallDataSurvay();
    
  },
  methods: {
    async ApiCallDataSurvay() {
      this.loading = true;
      let formData = new URLSearchParams();
 
      if (this.from == "") {
        formData.append("contact_line_id", this.dataClient.item.id);
      } else {
        formData.append(
          "contact_line_id",
          this.dataClient.item.contact_line.id
        );
      }

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getSurveySubmition,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev)console.log(res);
          if (res.data.codestatus == "S") {
            this.data = res.data.resultdata[0];
            this.dataSurvay = "";
            if (Config.modeDev)console.log(this.data);
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            if (Config.modeDev)console.log(error);
          }
          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              if (Config.modeDev) console.log(error);
            }
          }
        });

      this.loading = false;
    },
  },
};
</script>
