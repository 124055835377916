<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <h6 class="mb-0">{{ index + 1 }}. {{ item.title }}</h6>
    </template>

    <div class="row">
      <div class="col-12 text-right">
        Tidak Ada Data :
        <switches
          v-model="tidak_ada"
          type-bold="false"
          color="primary"
          class="ml-1 mb-0 float-right mt-2"
        ></switches>
      </div>
    </div>

    <div v-show="!tidak_ada">
      <b-form-group label="A. Contractual * : ">
        <select v-model="contractual_v" class="form-control">
          <template v-for="(item2, index2) in item.contracttual">
            <option :value="item2.id" :key="index2">
              {{ item2.value }}
            </option>
          </template>
        </select>
      </b-form-group>

      <b-form-group label="B. Keterangan : ">
        <textarea
          row="1"
          class="form-control"
          v-model="contractual_desc"
        ></textarea>
      </b-form-group>

      <div class="col-md-12 pl-0 pr-0" v-if="is_slide">
        <b-form-group label="Nilai * : "> </b-form-group>
        <div class="p-3">
          <div class="px-3">
            <vue-slide-bar
              v-model="nilai"
              :data="sliderWithLabel.data"
              :range="sliderWithLabel.range"
              @callbackRange="callbackRange"
              :process-style="
                nilai < 5
                  ? slider.processStyle.backgroundColor_l1
                  : nilai < 9
                  ? slider.processStyle.backgroundColor_l2
                  : slider.processStyle.backgroundColor_l3
              "
              :lineHeight="slider.lineHeight"
              :tooltipStyles="{
                backgroundColor: 'blue !important',
                borderColor: 'blue !important',
              }"
            />
          </div>
        </div>
      </div>

      <b-form-group label="D. Realisasi * : ">
        <select v-model="v_realisasi_computed" class="form-control">
          <option
            v-for="(item2, index2) in item.realisasi"
            :value="item2.id"
            :key="index2"
          >
            {{ item2.value }}
          </option>
        </select>
      </b-form-group>

      <b-form-group label="E. Keterangan : ">
        <textarea
          row="1"
          class="form-control"
          v-model="realisasi_desc"
        ></textarea>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
import { Helper } from "../../../../helper.js";
// import Config from "../../../../config.js";
import Switches from "vue-switches";
import VueSlideBar from "vue-slide-bar";
import Config from "../../../../config.js";

export default {
  mixins: [Helper],
  props: {
    index: Number,
    item: Object,
    dataInputSurvay: Object,
    callbackDataFormComponent: Function,
    isEdit: Boolean,
    dataEdit: Array,
  },
  components: {
    Switches,
    VueSlideBar,
  },
  data() {
    return {
      idques: "",
      load: true,
      slider: {
        lineHeight: 10,
        processStyle: {
          backgroundColor_l1: {
            backgroundColor: "#ed1111 !important",
          },
          backgroundColor_l2: {
            backgroundColor: "#fff585 !important",
          },
          backgroundColor_l3: {
            backgroundColor: "green !important",
          },
        },
      },

      is_slide: false,
      // mountedEdit: false,
      contractual_v: "",
      contractual: "",
      contractual_desc: "",
      nilai: 0,
      realisasi_v: "",
      realisasi_desc: "",

      dataSurvay: "Tidak Ada Data Survay",
      tidak_ada: false,
      sliderWithLabel: {
        value: 1,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        range: [
          {
            label: "Kurang",
          },
          {
            label: "Kurang Cukup",
            isHide: true,
          },
          {
            label: "Kurang Cukup",
            isHide: true,
          },
          {
            label: "Kurang Cukup",
            isHide: true,
          },
          {
            label: "Cukup",
          },
          {
            label: "Cukup",
            isHide: true,
          },
          {
            label: "Kurang Baik",
            isHide: true,
          },
          {
            label: "Kurang Baik",
            isHide: true,
          },
          {
            label: "Kurang Baik",
            isHide: true,
          },
          {
            label: "Baik",
          },
        ],
        rangeValue: {},
      },
    };
  },
  computed: {
    v_realisasi_computed: {
      get() {
        return this.realisasi_v;
      },
      set(val) {
        this.is_slide = true;
        this.realisasi_v = val;
        for (let iq = 0; iq < this.item.realisasi.length; iq++) {
          if (this.item.realisasi[iq].id == val) {
            this.nilai = this.item.realisasi[iq].answer_score;
          }
        }
      },
    },
  },
  watch: {
    // whenever question changes, this function will run
    contractual_v() {
      this.callDataB();
    },
    contractual_desc() {
      this.callDataB();
    },
    nilai() {
      this.callDataB();
    },
    realisasi_desc() {
      this.callDataB();
    },
    tidak_ada() {
      this.callDataB();
    },
  },
  mounted() {
    this.cekdataSudahInput();
  },
  methods: {
    async cekdataSudahInput() {
       let adaData;
      if(this.isEdit){
        adaData = false;
      }else{
        adaData = true;
      }
     

      let promise = new Promise((resolve, reject) => {
        try {
          for (let i = 0; i < this.dataInputSurvay.answers.length; i++) {
            if (this.dataInputSurvay.answers[i] != null) {
              if (this.item.id == this.dataInputSurvay.answers[i].question_id) {
                if (this.dataInputSurvay.answers[i].any_data == "yes") {
                  this.contractual_v =
                    this.dataInputSurvay.answers[i].answer.contractual;
                  this.contractual =
                    this.dataInputSurvay.answers[i].answer.contractual;

                  this.contractual_desc =
                    this.dataInputSurvay.answers[i].answer.contractual_desc;

                  this.nilai = parseInt(
                    this.dataInputSurvay.answers[i].answer.nilai
                  );
                  this.is_slide = true;

                  this.realisasi_v =
                    this.dataInputSurvay.answers[i].answer.realisasi;

                  this.realisasi_desc =
                    this.dataInputSurvay.answers[i].answer.realisasi_desc;
                  adaData = true;
                  resolve(true);
                  break;
                } else {
                  adaData = false;
                  resolve(true);
                }
              }
            }
          }

          resolve(false);
        } catch (e) {
          reject(e);
        }
      });

      await promise.then((res) => {

        if (this.isEdit && !res) {
          if (Config.modeDev) console.log(this.dataEdit);
          for (let i = 0; i < this.dataEdit.length; i++) {
            if (this.dataEdit[i].question_id == this.item.id) {
              adaData = true;

              this.contractual_v = this.dataEdit[i].contractual.contractual_id;
              this.contractual = this.dataEdit[i].contractual.contractual_id;

              this.contractual_desc = this.dataEdit[i].contractual_desc;

              this.nilai = parseInt(this.dataEdit[i].nilai);
              this.is_slide = true;

              this.realisasi_v = this.dataEdit[i].realisasi.realisasi_id;

              this.realisasi_desc = this.dataEdit[i].realisasi_desc;

              break;
            }
          }
        }

        if (!adaData) {
          this.tidak_ada = true;
          // this.mountedEdit = false;
        }

        this.callDataB();
      });
    },
    callDataB() {
      let flag_lolos = false;
      this.contractual = this.contractual_v;

      let data = null;
      data = {
        question_id: this.item.id,
        answer: {
          contractual: this.contractual,
          contractual_desc: this.contractual_desc,
          nilai: String(this.nilai),
          realisasi: this.realisasi_v,
          realisasi_desc: this.realisasi_desc,
        },
      };

      if (this.tidak_ada == true) {
        flag_lolos = true;
      } else {
        if (
          this.contractual != "" &&
          this.nilai != "" &&
          this.realisasi_v != ""
        ) {
          flag_lolos = true;
        }
      }

      this.callbackDataFormComponent(
        data,
        this.item.indexAnswer,
        this.tidak_ada,
        flag_lolos,
        this.index
      );
    },

    callbackRange(val) {
      this.sliderWithLabel.rangeValue = val;
    },
  },
};
</script>
