<template>
  <div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <div class="table-responsive">
      <b-table
        class="table-centered"
        :items="ordersData"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(kodefikasi)="data">
          <a class="text-black" v-b-tooltip.hover :id="'tt' + data.item.id">
            {{ data.item.kodefikasi }}
          </a>

          <b-tooltip :target="'tt' + data.item.id" placement="rightbottom">
            <div
              style="
                width: 200px;
                min-height: 60px;
                position: relative;
                margin-top: -10px;
                margin-left: -10px;
              "
            >
              <span
                style="
                  font-weight: bold;
                  text-align: center;
                  font-size: 40px;
                  color: rgba(255, 255, 255, 0.2);
                "
              >
                {{ userStart.ad_user_name }}</span
              >
              <div
                class="text-white"
                style="
                  padding-top: 3px;
                  font-size: 14px;
                  text-align: center;
                  margin: auto;
                  position: absolute;
                  top: 25%;
                  bottom: 25%;
                  left: 5%;
                  right: 5%;
                  min-height: 60px;
                "
              >
                {{ data.item.parner_name }}
              </div>
            </div>
          </b-tooltip>
        </template>
        <template v-slot:cell(status)="data">
          <div style="width:60px;">
            <div style="text-align: center; float:left;" v-if="data.item.status == 'done'">
              <a class="text-success" v-b-tooltip.hover title="Approve">
                <i class="ri-checkbox-circle-fill font-size-18"></i>
              </a>
            </div>

            <div style="text-align: center; float:left;" v-if="data.item.status != 'done'">
              <a class="text-success" v-b-tooltip.hover title="Waiting Approve">
                <i class="ri-time-line font-size-18"></i>
              </a>
            </div>

            <div style="text-align: center; float:left; margin-left:10px; padding-top:5px;">
              <a
                @click="data.toggleDetails"
                class="text-primary"
                v-b-tooltip.hover
                title="View Detail"
                style="cursor:pointer;"
              >
                <i class="far fa-eye font-size-18" style="margin-top: -5px"></i>
              </a>
            </div>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <div class="row">
              <div class="col-lg-4">
                <div>
                  <table class="tbdetail">
                    <tr>
                      <td>Manager Contruction </td>
                      <td>:</td>
                      <td>{{ row.item.nama_mk }}</td>
                    </tr>
                    <tr>
                      <td>Quantity Surveyor</td>
                      <td>:</td>
                      <td>{{ row.item.nama_qs }}</td>
                    </tr>
                    <tr>
                      <td>State</td>
                      <td>:</td>
                      <td>{{ row.item.state_name }}</td>
                    </tr>
                    <tr>
                      <td>City</td>
                      <td>:</td>
                      <td>{{ row.item.kota_name }}</td>
                    </tr>
                    <tr>
                      <td>Sector Name</td>
                      <td>:</td>
                      <td>{{ row.item.sektor_name }}</td>
                    </tr>
                    <tr>
                      <td>Value Project</td>
                      <td>:</td>
                      <td>
                        Rp
                        {{ numberWithCommas(row.item.nilai_proyek) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Contract Status</td>
                      <td>:</td>
                      <td>{{ row.item.status_contract_name }}</td>
                    </tr>
                    <tr>
                      <td>Contract Year</td>
                      <td>:</td>
                      <td>{{ row.item.tahun_kontrak }}</td>
                    </tr>
                    <tr>
                      <td>Segmentation</td>
                      <td>:</td>
                      <td>{{ row.item.segmentasi_name }}</td>
                    </tr>
                    <tr>
                      <td>Contract Type</td>
                      <td>:</td>
                      <td>{{ row.item.jenis_kontrak_name }}</td>
                    </tr>
                    <tr>
                      <td>Project Package</td>
                      <td>:</td>
                      <td>{{ row.item.paket_pekerjaan_name }}</td>
                    </tr>
                    <tr>
                      <td>Payment Type</td>
                      <td>:</td>
                      <td>{{ row.item.jenis_pembayaran_name }}</td>
                    </tr>
                  </table>
                </div>

              </div>
              <div class="col-lg-8">
                <ComponentHasilSurvay :data-client="row" :from="''" />
              </div>
            </div>
          </b-card>
        </template>
      </b-table >
      <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
    </div>
    <div class="row mb-5">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import axios from "axios";
import { Helper } from "../../../../helper.js";
import Config from "../../../../config.js";
// import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";
// import VueNumeric from "vue-numeric";
// import { Circle8 } from "vue-loading-spinner";
// import moment from "moment";
import ComponentHasilSurvay from "./ComponentHasilSurvay.vue";
// import ComponentFormAs from "./ComponentFormAs.vue";
// import ComponentApproval from "./ComponentApproval.vue";

import "vue-custom-range-slider/dist/vue-custom-range-slider.css";
import Swal from "sweetalert2";

export default {
  mixins: [Helper],
  components: {
    // Layout,
    // PageHeader,
    // Multiselect,
    // Circle8,
    // DatePicker,
    // VueNumeric,
    ComponentHasilSurvay,
    // ComponentFormAs,
    // ComponentApproval,
  },
  data() {
    return {
      data_url: Config,
      dataFlagSurveyPerQues: [],

      userStart: JSON.parse(localStorage.getItem("user")),
      errors: [],
      status_contract: "",
      tahun_kontrak: "",

      dataSurvay: "",
      dataNomor: 0,
      dataInputSurvay: {
        survey_id: "",
        answers: [],
      },
      indexSection: 0,
      indexSectionHigh: 0,
      totalSection: 0,

      loading_send: false,

      tab: 1,
      title: this.$root.$t("pageKarya.clientAssesment.title"),
      loading: false,
      items: [
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_1"),
        },
        {
          text: this.$root.$t("pageKarya.clientAssesment.text_menu_top_2"),
          active: true,
        },
      ],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        {
          key: "kodefikasi",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th0"),
        },
        {
          key: "sektor_name",
          sortable: true,
          label: this.$root.$t("Sector Name"),
        },
        {
          key: "nama_proyek",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th2"),
        },
        {
          key: "kota_name",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th3"),
        },
        {
          key: "paket_pekerjaan_name",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th4"),
        },
        {
          key: "tahun_kontrak",
          sortable: true,
          label: this.$root.$t("pageKarya.clientAssesment.table.th5"),
        },
        { key: "status" },
      ],
    };
  },
  computed: {
    rows() {
      return this.ordersData.length;
    },
  },
  mounted() {
    this.ApiCallDataClientAssesment();
    this.totalRows = this.ordersData.length;
  },

  methods: {
    async ApiCallDataClientAssesment() {
      this.loading = true;
      let formData = new URLSearchParams();
      
      formData.append("perpage", 9999);
      formData.append("page", 1);

      if(this.userStart.role.code == '2'){
        formData.append("status", "done");
        formData.append("is_manager", "Y");
      } 

      if (Config.modeDev) console.log(this.userStart);
      await axios
        .post(
          process.env.VUE_APP_AUTHDOMAIN + this.data_url.getContactNew,
          formData,
          {
            headers: {
              "Forca-Token": this.userStart.token,
              // "Accept": "application/json",
              // "Content-Type": "application/x-www-form-urlencoded" // "text/html"
            },
          }
        )
        .then((res) => {
          if (Config.modeDev) console.log(res);
          if (res.data.codestatus == "S") {
            this.ordersData = res.data.resultdata;
            this.totalRows = this.ordersData.length;
          } else {
            this.wrongTokenCo(res);
          }
        })
        .catch((error) => {
          if (this.is_runTest == false) {
            console.log(error);
          }

          try {
            if (error.response.status === 401) {
              this.callLogOut();
            }
          } catch (error) {
            if (this.is_runTest == false) {
              console.log(error);
            }
          }
        });

      this.loading = false;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    approve() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, Approve it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            swalWithBootstrapButtons.fire(
              "Approved!",
              "Your file has been approved.",
              "success"
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your imaginary file is safe :)",
              "error"
            );
          }
        });
    },
  },
};
</script>
